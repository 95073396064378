/* Base Styles (Default for larger screens, including 1200px and above) */
.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust space between logo and search bar */
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 10px; 
}

/* Medium Screens (768px to 1200px) */
@media (max-width: 1200px) {
  .navbar {
    padding: 0 15px; /* Reduce side padding */
  }

  .navbar-left {
    gap: 30px; /* Reduce gap between logo and search bar */
  }

  .navbar-right {
    gap: 10px; /* Slightly reduce gap between icons */
  }
}

/* Small Screens (480px to 767px) */
@media (max-width: 768px) {
  .navbar {
    height: auto; /* Allow navbar to grow if needed */
    flex-wrap: wrap; /* Wrap content to avoid overflow */
    padding: 0 10px;
  }

  .navbar-left {
    width: 100%; /* Take full width */
    justify-content: center; /* Center logo and search bar */
    gap: 20px; /* Reduce gap */
    margin-bottom: 10px; /* Add space below */
  }

  .navbar-right {
    width: 100%; /* Take full width */
    justify-content: center; /* Center icons and button */
    gap: 8px; /* Reduce gap further */
  }
}

/* Extra Small Screens (Below 480px) */
@media (max-width: 480px) {
  .navbar {
    flex-direction: column; /* Stack everything vertically */
    align-items: center; /* Center all content */
    padding: 10px; /* Add padding */
    
  }

  .navbar-left {
    flex-direction: column; /* Stack logo and search bar vertically */
    gap: 10px; /* Reduce gap further */
  }

  .navbar-right {
    flex-direction: row; /* Keep icons in a row */
    gap: 5px; /* Minimal gap for tiny screens */
  }
}



  