/* EnquiriesDataGrid.css */

.MuiDataGrid-root .MuiDataGrid-cell {
    border: 1px solid #e0e0e0;
  }
  
  .MuiDataGrid-root .MuiDataGrid-row {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .bold-header-cell {
    font-weight: bold;
  }
  
  .bold-header {
    font-weight: bold;
  }
  