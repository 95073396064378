.logo {
    position: relative;
    width: 150px;
    height: 50px;
    opacity: 1
  }
  
  .logo-image {
    width: 100%;
    height: 100%; 
    object-fit: contain; 
  }
  