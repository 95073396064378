.rbc-event-label {
  display: none;
}

.custom-header {
  font-weight: bold;
  font-size: 0.5rem;
}

.rbc-row{
  font-size:large;
 }

 .rbc-off-range-bg{
  background: white;
 }

 .rbc-event-content {
  white-space: normal !important;

}
.rbc-header{
font-size: 70%;
color: black;
}
