.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__day--highlighted-custom-3 {
  background-color: red;
  color: white;
}

/* Custom styles for unavailable dates */
.react-datepicker__day--highlighted-custom-4 {
  background-color: green;
  color: white;
}

.apexcharts-toolbar{
  display: none !important;
}

.rounded-bars-chart path {
  shape-rendering: crispEdges;
}

.rounded-bars-chart path:not([fill='none']):not(:hover) {
  rx: 5px;
}

.MuiDataGrid-colCell:not(:last-child) {
  border-right: none;
}


.TextField-without-border-radius fieldset {
  border-radius: 5;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.row-even {
  background-color: #ffffff; /* White row */
}

.row-odd {
  background-color: #f5f5f5; /* Light gray row */
}

.scanner-border{
 
  background: white; /* Background color inside the div */
  border-radius: 7px; /* Optional: Adds rounded corners */
  background-image: linear-gradient(45deg, #ff0000, #00ff00, #0000ff, rgb(250, 0, 255)); /* Gradient border colors */
  background-origin: border-box; /* Ensure the background is applied only within the border area */
  background-clip: border-box; /* Clip the background to the border */
  border: 0px solid transparent; /* Transparent border so the gradient will show through */
  
}

