/* Base styles for card item */
.card-item {
    width: 100%;
  
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f4f9fb;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .card-img {
    width: 40px;
    height: 40px;
    margin-right: 1em;
  }
  
  /* Hover effect */
  .card-item:hover {
    transform: translateY(-5px);
  }
  
  /* Card title styles */
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333333;
    margin: 0;
  }
  
  /* Card amount styles with animation */
  .card-amount {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0px;
    animation: countAnimation 1s ease-out;
  }
  
  /* Animation keyframes */
  @keyframes countAnimation {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Card count styles */
  .card-count {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
    color: var(--clr-p);
    border: 1px solid lightgrey;
    border-radius: 50%;
    padding: 0.4em;
  }
  
  /* Top container styles */
  .top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1em;
  }
  
  /* Bottom container styles */
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Media Queries for responsiveness */
  @media screen and (max-width: 1200px) {
    .row {
      justify-content: center; /* Center the cards on smaller screens */
    }
  }
  
  @media screen and (max-width: 1000px) {
    .row {
      flex-wrap: wrap; /* Allow the cards to wrap to the next line on smaller screens */
    }
  
    .col-md-3 {
      width: 50%; /* Display two cards in a row on screens between 1000px and 1200px */
    }
  
    .card-item{
      width: 100%;
      margin: 5px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .col-md-3 {
      width: 100%;
       /* Display one card per row on screens below 800px */
    }
  
    .card-item {
      padding: 1rem;
      width: 100%;
      margin: 5px; 
    }
  
    .card-title {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
  
    .card-amount {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
    }
  
    .card-count {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  }