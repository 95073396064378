.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-navbar {
  background-color: #007bff; /* Navbar color */
  height: 60px; /* Adjust based on actual Navbar height */
}

.layout-body {
  display: flex;
  flex: 1;
}

/* Sidebar */
.layout-sidebar {
  width: 210px; /* Default expanded width */
  background-color: #fff;
  padding: 1rem;
  position: fixed;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
}

.drawer-closed .layout-sidebar {
  width: 60px;
  padding: 1rem 0.5rem;
}

/* Main Content */
.layout-content {
  flex: 1;
  padding: 2rem;

  /* overflow-y: auto; */
  margin-left: 210px; /*addd */
  max-width: calc(100vw - 220px); /*addd */
  margin-top: 60px;
  background-color: #f8fbf5;
  min-height: calc(100vh - 60px);
  transition: margin-left 0.3s ease-in-out;
}

.drawer-closed .layout-content {
  margin-left: 60px;
  max-width: calc(100vw - 70px);
}

/* Small Screens (480px to 767px) */
@media (max-width: 768px) {
  .layout-sidebar {
    /* width: 60px;*/
    margin-top: 60px;
  }

  .layout-content {
    margin-top: 100px;
  }

  .drawer-closed .layout-content,
  .layout-content {
    margin-left: 60px;
  }

  .drawer-open .layout-content {
    margin-left: 210px;
  }
}

@media (max-width: 480px) {
  .layout-sidebar {
    /* width: 60px;*/
    margin-top: 150px;
  }

  .layout-content {
    margin-top: 200px;
  }

  .drawer-closed .layout-content,
  .layout-content {
    margin-left: 60px;
  }

  .drawer-open .layout-content {
    margin-left: 210px;
  }
}
