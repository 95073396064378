.group {
    height: 397px;
    width: 350px;
  }
  
  .group .overlap {
    background-color: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 0px 15px 1px #0000000d;
    height: 397px;
    position: relative;
    width: 350px;
  }
  
  .group .icon-users {
    height: 13px;
    left: 20px;
    position: absolute;
    top: 292px;
    width: 19px;
  }
  
  .group .group-wrapper {
    background-color: #019e6f;
    border-radius: 25.5px;
    height: 40px;
    left: 200px;
    position: absolute;
    top: 329px;
    width: 120px;
  }
  
  .group .div {
    height: 20px;
    left: 20px;
    position: relative;
    top: 7px;
    width: 119px;
  }
  
  .group .icon-arrow-left {
    height: 10px;
    left: 70px;
    position: absolute;
    top: 8px;
    width: 10px;
  }
  
  .group .text-wrapper {
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 15px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: 95px;
  }
  
  .group .icon-chalkboard {
    height: 15px;
    left: 20px;
    position: absolute;
    top: 219px;
    width: 19px;
  }
  
  .group .overlap-group {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #000000;
    border-radius: 21px;
    box-shadow: 0px 0px 15px 1px #0000000d;
    height: 166px;
    left: 20px;
    position: absolute;
    top: 21px;
    width: 166px;
  }
  
  .group .image {
    height: 66px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 49px;
    width: 154px;
  }
  
  .group .study-abroad-GMAT {
    color: #000000;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    height: auto;
    left: 200px;
    letter-spacing: 0;
    line-height: auto;
    position: absolute;
    top: 53px;
  }
  
  .group .p {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 223px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 140px;
  }
  
  .group .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 223px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 130px;
  }
  
  .group .vector {
    height: 15px;
    left: 201px;
    position: absolute;
    top: 161px;
    width: 15px;
  }
  
  .group .text-wrapper-3 {
    color: #404040;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 223px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 213px;
  }
  
  .group .text-wrapper-4 {
    color: #404040;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 223px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 249px;
  }
  
  .group .text-wrapper-5 {
    color: #404040;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 223px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 285px;
  }
  
  .group .text-wrapper-6 {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 213px;
  }
  
  .group .text-wrapper-7 {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 249px;
  }
  
  .group .text-wrapper-8 {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 285px;
  }
  
  .group .img {
    height: 3px;
    left: 19px;
    object-fit: cover;
    position: absolute;
    top: 204px;
    width: 430px;
  }
  
  .group .vector-2 {
    height: 14px;
    left: 21px;
    position: absolute;
    top: 255px;
    width: 18px;
  }
  
  .group .overlap-group-wrapper {
    height: 22px;
    left: 200px;
    position: absolute;
    top: 28px;
    width: 93px;
  }
  
  .group .div-wrapper {
    background-color: #ffeec9;
    border-radius: 12.5px;
    height: 22px;
    position: relative;
    width: 91px;
  }
  
  .group .text-wrapper-9 {
    color: #3d3d3d;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2px;
  }
  
  .group .icon-alternate {
    height: 15px;
    left: 200px;
    position: absolute;
    top: 132px;
    width: 17px;
  } 

