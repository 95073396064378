.main__wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: calc(100% - 2 * 1.5rem);
    max-width: 62.5rem;
  }
  .main__content {
    background-color: #1967d2;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    overflow: hidden;
  }
  .main__wrapper1 {
    height: 15rem;
    position: relative;
    width: 100%;
  }
  .main__bgImage {
    background-image: url(https://gstatic.com/classroom/themes/img_backtoschool.jpg);
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .main__emptyStyles {
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    background-image: radial-gradient(
      25rem 18.75rem ellipse at bottom right,
      #4285f4,
      transparent
    );
  }
  .main__text {
    position: relative;
    padding: 1.5rem;
  }
  .main__heading {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 2.75rem;
    color: #fff;
  
    margin: 0;
  }
  .main__overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
  .main__section {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.75rem;
    color: #fff;
  }
  .main__wrapper2 {
    color: #fff;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
  .main__code {
    font-weight: 500;
    font-style: normal;
  }
  .main__announce {
    display: flex;
    /* justify-content: center; */
    flex-shrink: 0;
    margin-top: 1rem;
  }
  .main__announcements {
    padding: 1rem;
    overflow: hidden;
    margin: -2.4rem;
    margin-left: 1rem;
    /* margin-top: 1.5rem; */
  }
  .main__subText {
    margin-top: 1.5rem;
    color: #807373;
    font-weight: 400;
  }
  .main__status {
    border: 1.5px solid #dadce0;
    padding: 20px;
    border-radius: 10px;
    width: 10vw;
    height: 15vh;
  }
  .main__announcementsWrapper {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 2px 6px 2px rgba(60, 64, 67, 0.149);
    border-radius: 0.5rem;
    overflow: hidden;
    min-height: 4.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
  .main__ancContent {
    padding: 30px;
    width: 70vw;
    color: rgba(0, 0, 0, 0.549);
  }
  
  .main__wrapper100 {
    display: flex !important;
    cursor: pointer;
    align-items: center;
  }
  .main__wrapper100 > * {
    margin-right: 20px;
  }
  #filled-multiline-flexible {
    width: 50vw !important;
    height: 20vh !important;
  }
  .main__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .main__buttons {
    display: flex;
    justify-content: space-between;
    width: 50vw;
    margin-top: 20px;
  }
  @media only screen and (max-width: 842px) {
    .main__status {
      display: none;
    }
  }